<mat-paginator
  #paginator
  [length]="pagiConfigs.total"
  (page)="pageChanged($event)"
  [pageIndex]="pagiConfigs.page"
  [pageSize]="pagiConfigs.limit"
  [pageSizeOptions]="pageSizeOptions"
  aria-label="Select page"
  class="bg-transparent"
  [class]="isBorder ? '' : 'pagination-border'"
>
</mat-paginator>
