<div class="radio-group">
  <mat-radio-group
    aria-label="Select an option"
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
    [disabled]="isDisabled"
    [ngClass]="className"
  >
    <ng-container *ngFor="let item of listItem">
      <mat-radio-button
        [value]="item[valueKey]"
        [ngClass]="{
          active: value === item[valueKey],
          disabled: item['disabled']
        }"
        [disabled]="item['disabled']"
        (click)="!item['disabled'] && handleClickRadio({ event: $event, value: item[valueKey] })"
        >{{ item[labelKey] }}</mat-radio-button
      >
    </ng-container>
  </mat-radio-group>
</div>
