import { Directive, forwardRef, HostListener } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[trim]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrimDirective),
      multi: true
    }
  ]
})
export class TrimDirective extends DefaultValueAccessor {
  @HostListener('input', ['$event.target.value'])
  ngOnChange = (val: string): void => {
    this.onChange(val.trim());
  };

  @HostListener('blur', ['$event.target.value'])
  applyTrim(val: string): void {
    this.writeValue(val.trim());
  }

  override writeValue(value: any): void {
    if (typeof value === 'string') {
      value = value.trim();
    }

    super.writeValue(value);
  }
}
