<ng-container *ngIf="type === checkboxType['TWO']; then TwoVar"> </ng-container>
<ng-container *ngIf="type === checkboxType['THREE']; then ThreeVar"> </ng-container>

<ng-template #TwoVar>
  <div class="checkbox">
    <input
      type="checkbox"
      [ngModel]="checked"
      (ngModelChange)="onModelChange($event)"
      class="cursor-pointer"
    />
    <div
      class="line"
      [ngClass]="{
        active: checked
      }"
    ></div>
    <div
      class="icon"
      [ngClass]="{ right: checked }"
      #icon
    ></div>
  </div>
</ng-template>

<ng-template #ThreeVar>
  <div class="checkbox">
    <!-- <input
      type="checkbox"
      [ngModel]="checked"
      (ngModelChange)="onModelChange($event)"
      class="cursor-pointer"
    />
    <div
      class="line"
      [ngClass]="{
        active: checked
      }"
    ></div>
    <div
      class="icon"
      [ngClass]="{ right: checked }"
      #icon
    ></div> -->

    <input
      type="hidden"
      [ngModel]="checked"
      (ngModelChange)="onModelChange($event)"
    />
    <div
      class="rectangle transition-all"
      (click)="handleClick()"
      [ngClass]="{
        tick: checked !== 0,
      }"
    >
      <ng-container *ngIf="checked === 1">
        <mat-icon>check</mat-icon>
      </ng-container>
      <ng-container *ngIf="checked === 2">
        <mat-icon>remove</mat-icon>
      </ng-container>
    </div>
  </div>
</ng-template>
