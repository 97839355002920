/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export enum CheckboxEnum {
  TWO = 'TWO',
  THREE = 'THREE'
}
@Component({
  selector: 'app-checkbox-comp',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppCheckBoxComponent),
      multi: true
    }
  ]
})
export class AppCheckBoxComponent implements ControlValueAccessor {
  @Input() type: string = CheckboxEnum.TWO;

  checkboxType = CheckboxEnum;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  constructor() {}

  checked = 0;

  writeValue(checked: number) {
    this.checked = checked;
  }

  onModelChange(e: number) {
    this.checked = e;
    this.onChange(e);
  }

  handleClick() {
    if (this.checked === 0) {
      this.checked = 1;
      this.onModelChange(1);
    } else {
      this.onModelChange(0);
    }
  }
}
