import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';
import { SocketEvent } from '../helpers/utils.helper';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: Socket | null = null;

  constructor(private tokenService: TokenService) {}

  connect(namespace: string): void {
    this.socket = io(`${environment.common_url}/${namespace}`, {
      extraHeaders: {
        Authorization: `Bearer ${this.tokenService.get()}`,
        'Content-Type': 'application/json',
        'x-tenant-id': this.tokenService.getTenant() || ''
      }
    });

    this.socket.on('connect', () => {
      console.log(`Connected to WebSocket server at namespace: ${namespace}`);
    });
  }

  onNewNotification(): Observable<void> {
    return new Observable((observer) => {
      if (this.socket) {
        this.socket.on(SocketEvent.NEW_ANNOUNCEMENT, () => {
          observer.next();
        });

        return () => {
          if (this.socket) {
            this.socket.off(SocketEvent.NEW_ANNOUNCEMENT);
          }
        };
      }

      return () => observer.complete();
    });
  }

  // Disconnect the socket connection (optional)
  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
