import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { EditStatusService } from '../services/edit-status.service';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private editStatusService: EditStatusService) {}

  canDeactivate(component: CanComponentDeactivate): boolean {
    if (this.editStatusService.getEditingStatus()) {
      const result = window.confirm('保存されていませんがよろしいですか？');

      if (result) {
        this.editStatusService.setEditingStatus(false);
        this.editStatusService.setSortStatus(false);
      }

      return result;
    }
    return true;
  }
}
