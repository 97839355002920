/* eslint-disable @typescript-eslint/no-namespace */
import { Injectable, inject } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenService } from '../services/token.service';
import { Role } from '../models/auth.model';
import { NotifyService } from 'src/app/shared/components/notify/notify.service';
import { TranslateHelper } from '../helpers/translate.helper';

@Injectable()
export class SellerGuard implements CanActivate, CanActivateChild {
  currentUser: any = null;
  tenantID = '';
  role: any = null;
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private notifyService: NotifyService,
    private translate: TranslateHelper
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.getUser();

    if (this.tokenService.hasLogged()) {
      if (this.role === Role.MANAGER || this.role === Role.NORMAL) {
        return true;
      }
      this.redirect();
      return false;
    }

    this.router.navigate(['/auth/login'], {
      queryParams: {
        returnUrl: state.url
      }
    });
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.getUser();

    if (this.tokenService.hasLogged()) {
      if (this.role === Role.MANAGER || this.role === Role.NORMAL) {
        return true;
      }
      this.redirect();
      return false;
    }
    this.router.navigate(['/auth/login'], {
      queryParams: {
        returnUrl: state.url
      }
    });
    return false;
  }

  getUser() {
    if (this.tokenService.getTenant()) {
      this.tenantID = this.tokenService.getTenant();
    }

    if (this.tokenService.getUser()) {
      this.currentUser = JSON.parse(this.tokenService.getUser());
      this.getRole();
    }
  }

  getRole() {
    const tenant: any = this.currentUser?.tenants?.find((x: any) => x.id == this.tenantID);
    const isSystemAdmin = this.currentUser?.isSystemAdmin;
    this.role = tenant?.role;
    if (isSystemAdmin) {
      this.role = Role.MANAGER;
    }
  }

  redirect() {
    if (this.role === Role.MANAGER || this.role === Role.NORMAL) {
      this.router.navigateByUrl('db-material');
    } else {
      this.router.navigateByUrl('showroom/materials');
    }
    this.notifyService.error(this.translate.__m('ERR-COM-0018'));
  }
}

// export namespace SellerGuard {
//   export const canActivate = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
//     const tokenService = inject(TokenService);
//     const router = inject(Router);
//     let currentUser: any = null;
//     let tenantID = '';
//     let role: any = null;

//     const getUser = () => {
//       if (tokenService.getUser()) {
//         currentUser = JSON.parse(tokenService.getUser());
//         getRole();
//       }

//       if (tokenService.getTenant()) {
//         tenantID = tokenService.getTenant();
//       }
//     };

//     const getRole = () => {
//       const tenant: any = currentUser?.tenants?.find((x: any) => x.id == tenantID);
//       const isSystemAdmin = currentUser?.isSystemAdmin;
//       role = tenant?.role;
//       if (isSystemAdmin) {
//         role = Role.MANAGER;
//       }
//     };

//     const redirect = () => {
//       if (role === Role.MANAGER || role === Role.NORMAL) {
//         router.navigateByUrl('db-material');
//       } else {
//         router.navigateByUrl('order/purchase-order-list');
//       }
//     };

//     getUser();
//     if (tokenService.hasLogged()) {
//       if (role === Role.MANAGER) {
//         return true;
//       }
//       redirect();
//       return false;
//     }
//     router.navigate(['/auth/login'], {
//       queryParams: {
//         returnUrl: state.url
//       }
//     });
//     return false;
//   };

//   export const canActivateChild = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
//     canActivate(route, state);
// }
