import { Injectable } from '@angular/core';

enum NotifyType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info'
}

type NotificationOptions = {
  timeout?: number;
};

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  isShow: boolean;
  message: string;
  type: NotifyType | null;
  timer: any;

  constructor() {}

  success(msg: string, opts?: NotificationOptions) {
    this.show(opts);
    this.message = msg;
    this.type = NotifyType.INFO;
  }

  error(msg: string, opts?: NotificationOptions) {
    this.show(opts);
    this.message = msg;
    this.type = NotifyType.ERROR;
  }

  info(msg: string, opts?: NotificationOptions) {
    this.show(opts);
    this.message = msg;
    this.type = NotifyType.INFO;
  }

  show(
    opts: NotificationOptions = {
      timeout: 5000
    }
  ) {
    this.isShow = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.hide();
    }, opts.timeout);
  }

  hide() {
    this.isShow = false;
    this.clear();
  }

  clear() {
    this.message = '';
    this.type = null;
  }

  get isError() {
    return this.type === NotifyType.ERROR;
  }

  get isSuccess() {
    return this.type === NotifyType.SUCCESS;
  }

  get isInfo() {
    return this.type === NotifyType.INFO;
  }
}
