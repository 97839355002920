import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {
  locale: string;
  constructor(@Inject(LOCALE_ID) locale: string) {
    this.locale = locale;
  }

  transform(value: Date | string, format = 'yyyy/MM/dd'): string {
    const datePipe = new DatePipe(this.locale || 'ja-JP');
    return datePipe.transform(value, format) as string;
  }

  transformDateHours(value: Date | string, format = 'yyyy/MM/dd HH:mm'): string {
    const datePipe = new DatePipe(this.locale || 'ja-JP');
    return datePipe.transform(value, format) as string;
  }
}
