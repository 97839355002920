import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { FormBuilder, FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-radio-comp',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppRadioComponent),
      multi: true
    }
  ]
})
export class AppRadioComponent implements ControlValueAccessor {
  @Input() className: string | any = '';
  @Input() placeHolder: string | any = '';
  @Input() listItem: any[];
  @Input() labelKey: string = 'label';
  @Input() valueKey: string | any = 'value';

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  isDisabled: boolean = false;

  onChange: any = () => {};
  onTouch: any = () => {};
  value = '';

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  constructor() {}

  cb: any;
  writeValue(val: string) {
    this.value = val;
  }

  onModelChange(e: string) {
    this.value = e;
    this.onChange(e);
  }

  onHandleKeyPress(event: KeyboardEvent) {
    const { key } = event;
    if (key === 'Enter') {
      event.preventDefault();
      this.onEnter.emit(this.value);
    }
  }

  onHandleClear(event: Event) {
    this.onModelChange('');
    this.onClear.emit();
  }
  onHandleBlur(event: any) {
    this.blur.emit(event);
  }
  handleClickRadio({ event, value }: any) {
    event.preventDefault();
    if (value === this.value) {
      this.onModelChange('');
    } else {
      this.onModelChange(value);
    }
  }
}
