import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-detail-dialog',
  templateUrl: './notification-detail-dialog.component.html',
  styleUrls: ['./notification-detail-dialog.component.scss']
})
export class NotificationDetailDialogComponent {
  titleDialog = '';
  contentDialog = '';
  confirmButtonText = '';
  cancelButtonText = '';
  loading: boolean;

  // *** PLEASE NOT BINDING WIDTH, HEIGHT ***

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      content: string;
      textConfirm: string;
      textCancel: string;
      handleConfirm: () => void;
      hasLoading?: boolean;
    },
    private dialogRef: MatDialogRef<NotificationDetailDialogComponent>
  ) {
    this.titleDialog = this.data.title ? this.data.title : '';
    this.contentDialog = this.data.content ? this.data.content : '';
    this.cancelButtonText = this.data.textCancel ? this.data.textCancel : 'confirmDialog.textCancel';
  }

  onConfirm(): void {
    if (this.loading) return;

    if (this.data.hasLoading) {
      this.loading = true;
    }

    this.data.handleConfirm();

    if (!this.data.hasLoading) {
      this.dialogRef.close(true);
    }
  }
}
