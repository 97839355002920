import { Component, ElementRef, Renderer2, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggedUserResponse, RoleByName } from 'src/app/core/models/auth.model';
import { TokenService } from 'src/app/core/services/token.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { ProfileService } from '../../services/profile.service';
import { firstValueFrom } from 'rxjs';
import { NotifyService } from 'src/app/shared/components/notify/notify.service';
import { TranslateHelper } from 'src/app/core/helpers/translate.helper';
import * as moment from 'moment';
import { NotificationDetailDialogComponent } from '../notification-detail-dialog/notification-detail-dialog.component';
import { SocketService } from 'src/app/core/services/socket.service';
import { NameSpaceSocket } from 'src/app/core/helpers/utils.helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('toggleProfile') toggleProfile: ElementRef | undefined;
  @ViewChild('btnNotice') btnNotice: ElementRef<HTMLElement>;
  @ViewChild('notice') noticeEl: ElementRef<HTMLElement>;
  @ViewChild('noticeList') noticeListEl: ElementRef<HTMLElement>;

  roles = RoleByName;
  isUnread = false;
  listNotifications: any[] = [];
  notificationInfo: any = {
    page: 0,
    limit: 20
  };
  noticeLoading = true;
  canManageMaterial = false;
  timer: any;
  MAX_TIME_DOWNLOAD_LINK = 86400; //86400s
  notifications: any[] = [];
  constructor(
    private tokenService: TokenService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private utilsService: UtilsService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private profileService: ProfileService,
    private notifyService: NotifyService,
    private translate: TranslateHelper,
    private cookieService: CookieService,
    private socketService: SocketService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleProfile?.nativeElement && e.target !== this.toggleProfile?.nativeElement) {
        this.onHover = false;
      }
    });
    this.canManageMaterial = this.tokenService.canManageMaterial();
  }

  ngOnInit(): void {
    this.socketService.connect(NameSpaceSocket.ANNOUNCEMENT);
    this.socketService.onNewNotification().subscribe(() => {
      this.getNewNotification();
    });
    this.activeRouter.queryParams.subscribe((params: any) => {
      if (params?.notification_id) {
        this.router.navigate([], {
          queryParams: {
            notification_id: null
          },
          queryParamsHandling: 'merge',
          replaceUrl: true
        });

        this.profileService.getNotificationDetail(params?.notification_id).subscribe({
          next: (res) => {
            const data = res?.data || {};

            if (data && data?.transitionLink === 'modal') {
              this.dialog.open(NotificationDetailDialogComponent, {
                width: '85vw',
                maxWidth: '874px',
                height: '90vh',
                maxHeight: '800px',
                data: {
                  title: data.title,
                  content: data.contentDetail
                }
              });
            }
          }
        });
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.noticeListEl?.nativeElement) return;

    this.noticeListEl?.nativeElement.addEventListener('scroll', (e: any) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        this.notificationInfo.page < this.notificationInfo.pageCount - 1 &&
        !this.noticeLoading
      ) {
        this.notificationInfo.page += 1;
        this.getListNotifications();
      }
    });
    const limit = Math.ceil((this.noticeListEl?.nativeElement?.clientHeight || 50) / 50);
    this.notificationInfo.limit = limit;

    this.getListNotifications();

    window.addEventListener('click', (e: any) => {
      if (!this.noticeEl.nativeElement.contains(e.target) && !this.btnNotice.nativeElement.contains(e.target)) {
        this.noticeEl.nativeElement.classList.remove('show');
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  getLoggedEmail(): string {
    if (this.tokenService.getUser()) {
      const user: LoggedUserResponse = JSON.parse(this.tokenService.getUser());
      return user?.email;
    } else {
      return 'username';
    }
  }

  removeDuplicateNotification(notifications: any[]) {
    const idMap = new Map();
    notifications.forEach((item) => idMap.set(item.id, item));
    return Array.from(idMap.values());
  }

  getNewNotification() {
    const page = 0;

    this.noticeLoading = true;
    this.profileService
      .getNotifications(page, this.notificationInfo.limit, this.isUnread)
      .subscribe({
        next: (res) => {
          const newData = res?.data?.data || [];
          this.listNotifications = this.removeDuplicateNotification([...newData, ...this.listNotifications]);

          this.notificationInfo = {
            ...this.notificationInfo,
            ...(res?.data?.meta || {}),
            page: this.notificationInfo.page
          };
        }
      })
      .add(() => {
        this.noticeLoading = false;
      });
  }

  refreshNotification() {
    this.notificationInfo.page = 0;
    this.listNotifications = [];
    this.getListNotifications();
  }

  getAvatar(): string {
    if (this.tokenService.getUser()) {
      const user: LoggedUserResponse = JSON.parse(this.tokenService.getUser());
      return this.utilsService.getCloudFrontUrl(user?.profileImageUrl, false);
    } else {
      return 'username';
    }
  }

  get getFullName(): string {
    if (this.tokenService.getUser()) {
      const user: LoggedUserResponse = JSON.parse(this.tokenService.getUser());
      return user?.familyName + ' ' + user?.givenName;
    } else {
      return this.getLoggedEmail();
    }
  }

  onHover = false;

  enter() {
    this.onHover = true;
  }

  logout() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'tenantSetting.confirmDialog.title',
        content: 'tenantSetting.confirmDialog.content',
        textConfirm: 'tenantSetting.confirmDialog.logout',
        handleConfirm: () => this.onLogout()
      }
    });
  }

  onLogout() {
    const email = this.getLoggedEmail();
    this.tokenService.removeToken();
    this.deleteAllCookies();

    this.router.navigate(['auth/logout-announcement'], {
      queryParams: { email: email }
    });
  }

  deleteAllCookies() {
    const domain = `.${document.domain}`;
    this.cookieService.deleteAll('/', domain);
    this.cookieService.deleteAll('/', `.${environment.rootDomain}`);
  }

  navigateTenantSetting() {
    window.location.href = '/tenant/basic-setting';
  }

  toggleNotice() {
    if (this.noticeEl) {
      this.noticeEl.nativeElement.classList.toggle('show');

      if (this.noticeEl.nativeElement.classList.contains('show')) {
        this.refreshNotification();
      }
    }
  }

  unReadChange() {
    this.refreshNotification();
  }

  getListNotifications() {
    this.noticeLoading = true;
    this.profileService
      .getNotifications(this.notificationInfo.page, this.notificationInfo.limit, this.isUnread)
      .subscribe({
        next: (res) => {
          const newData = res?.data?.data || [];
          this.listNotifications = this.removeDuplicateNotification([...this.listNotifications, ...newData]);

          this.notificationInfo = {
            ...this.notificationInfo,
            ...(res?.data?.meta || {})
          };
        }
      })
      .add(() => {
        this.noticeLoading = false;
      });
  }

  markAllAsRead() {
    this.listNotifications.map((item) => {
      item.hasRead = true;
    });
    return this.profileService.markAllAsRead().subscribe({
      next: (res) => {
        this.notificationInfo.unreadCount = 0;
      }
    });
  }

  async handleClickNotice(item: any) {
    const routerLink = item.transitionLink || '';
    const downloadLink = item.link || '';

    const tenantId = this.tokenService.getTenant();
    const listDomain = this.tokenService.getSubdomain();

    if (!item.hasRead) {
      item.hasRead = true;
      await firstValueFrom(this.profileService.markAsRead(item.id));
      this.notificationInfo.unreadCount -= 1;
    }

    const currentSubDomain = listDomain.find((x: any) => x.id === tenantId)?.subDomain || '';
    const targetSubDomain = listDomain.find((x: any) => x.id === item.tenantId)?.subDomain || '';
    if (routerLink === 'modal') {
      if (currentSubDomain !== targetSubDomain) {
        const url = this.replaceSubDomain(currentSubDomain, targetSubDomain);
        window.open(url + '?notification_id=' + item.id, '_blank');
        return;
      }

      this.profileService.getNotificationDetail(item.id).subscribe({
        next: (res) => {
          const data = res?.data || {};
          this.dialog.open(NotificationDetailDialogComponent, {
            width: '85vw',
            maxWidth: '874px',
            height: '90vh',
            maxHeight: '800px',
            data: {
              title: data.title,
              content: data.contentDetail
            }
          });
        }
      });

      return;
    }

    if (routerLink) {
      if (currentSubDomain !== targetSubDomain) {
        const url = this.replaceSubDomain(currentSubDomain, targetSubDomain);
        window.open(url + routerLink, '_blank');
        return;
      }

      window.location.href = routerLink;
    }

    if (downloadLink) {
      if (moment().diff(moment(item.createdAt), 'seconds') > this.MAX_TIME_DOWNLOAD_LINK) {
        this.notifyService.error(this.translate.__m('ERR-CSYS-0001'));
        return;
      }

      window.open(downloadLink, '_blank');
    }
  }

  replaceSubDomain(currentSubDomain: string, subDomain: string) {
    const [scheme, domain] = window.location.origin.split('://');
    const rootDomain = environment.rootDomain;

    if (!subDomain) {
      return `${scheme}://user.${rootDomain}`;
    }

    const subDomainSuffix = subDomain.includes('plm') ? '' : '.srm';
    const newDomain =
      currentSubDomain && domain.includes(currentSubDomain)
        ? subDomain + subDomainSuffix + '.' + rootDomain
        : subDomain + subDomainSuffix + '.' + rootDomain;

    return `${scheme}://${newDomain}`;
  }

  get totalNoticeUnread(): string | null {
    if (!this.notificationInfo?.unreadCount) {
      return null;
    }

    if (this.notificationInfo?.unreadCount > 99) {
      return '99+';
    }

    return this.notificationInfo?.unreadCount;
  }
}
