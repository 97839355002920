<div
  class="input-select"
  [ngClass]="[
    className,
    (valueCenter && 'input-select-center') || '',
    (disabled && 'disabled') || '',
    (readAsLabel && 'input-label') || ''
  ]"
>
  <ng-container *ngIf="readAsLabel">
    <input
      class="input-readOnly"
      [value]="getSelectOption"
      [readOnly]="true"
    />
  </ng-container>

  <ng-container *ngIf="!readAsLabel">
    <ng-container *ngIf="multiple">
      <mat-select
        [ngModel]="selected"
        [placeholder]="placeHolder"
        (ngModelChange)="onModelChange($event)"
        [disabled]="disabled"
        multiple
        [ngClass]="
          {
            selectClassName, 
          }
        "
        [panelClass]="'select-panel'"
      >
        <ng-container *ngFor="let option of options">
          <mat-option [value]="(valueKey && option[valueKey]) || option">{{
            (labelKey && option[labelKey]) || option
          }}</mat-option>
        </ng-container>
      </mat-select>
    </ng-container>
    <ng-container *ngIf="!multiple">
      <mat-select
        [ngModel]="selected"
        [placeholder]="placeHolder"
        (ngModelChange)="onModelChange($event)"
        [disabled]="disabled"
        [ngClass]="selectClassName"
        [panelClass]="'select-panel'"
      >
        <ng-container *ngFor="let option of options">
          <mat-option [value]="(valueKey && option[valueKey]) || option">{{
            (labelKey && option[labelKey]) || option
          }}</mat-option>
        </ng-container>
      </mat-select>
    </ng-container>
  </ng-container>
</div>
