import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { ListAbstractControl } from 'src/app/core/models/common.model';

@Component({ template: '' })
export abstract class FormAbstractComponent {
  abstract _form: FormGroup;
  _controls: ListAbstractControl = {};
  _i18nKey = '';

  @Output() submitted = new EventEmitter<any>();
  @Input() loading = false;

  set i18nKey(key: string) {
    this._i18nKey = key;
  }

  set form(form: FormGroup) {
    this._form = form;
  }

  get form() {
    return this._form;
  }

  get controls(): ListAbstractControl {
    return this._controls;
  }

  getAllControls(): void {
    const keys = Object.keys(this.form?.controls || {});
    for (const i in keys) {
      this._controls[keys[i]] = this.form.get(keys[i]);
    }
  }

  submitClick(): void {
    if (this.form.invalid) {
      return;
    }

    const data: any = this.form.getRawValue();
    this.submitted.emit(data);
  }

  starSymbol(): string {
    return '<span class="text-[#c00000] mr-1">※</span>';
  }

  getErrorMessage(controlName: string, control?: any): string {
    const errors: ValidationErrors | null | undefined = this.controls[controlName]?.errors || control?.errors;
    if (!errors) {
      return '';
    }

    const errorCode = Object.keys(errors)[0];
    return `${this._i18nKey}.${controlName}.${errorCode}Error`;
  }

  getFirstError(controls?: any): string {
    if (!this.form?.touched) return '';

    const objControl = controls || this.controls;

    const keys = Object.keys(objControl);

    for (const i in keys) {
      const control = objControl[keys[i]] as any;

      if (control?.touched && (control?.invalid || control?.errors)) {
        if (control?.controls) {
          return this.getFirstError(control.controls);
        }

        return this.getErrorMessage(keys[i], control);
      }
    }

    return '';
  }

  getFirstErrorTree(form: any, controls?: any, parentName: string[] = []): string {
    if (!form?.touched) return '';

    const objControl = controls || form.controls;

    const keys = Object.keys(objControl);

    for (const i in keys) {
      const control = objControl[keys[i]] as any;

      if (control?.touched && (control?.invalid || control?.errors)) {
        if (control?.controls) {
          parentName?.push(keys[i]);

          return this.getFirstErrorTree(form, control.controls, parentName);
        }

        return this.getErrorMessage([...parentName, keys[i]].join('.'), control);
      }
    }

    return '';
  }
}
