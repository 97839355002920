import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiSuccessResponse, ObjectWithUnknowProperty, ApiErrorResponse } from 'src/app/core/models/common.model';
import {
  ChangeEmailData,
  ChangePasswordData,
  ChangePasswordResponse,
  UpdateProfileData,
  UpdateProfileResponse
} from 'src/app/core/models/profile.model';
import { HttpService } from 'src/app/core/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpService) {}

  public updateProfile(
    data: UpdateProfileData
  ): Observable<ApiSuccessResponse<UpdateProfileResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.put(`auth/me`, data, environment.common_url);
  }

  public changePassword(
    data: ChangePasswordData
  ): Observable<ApiSuccessResponse<ChangePasswordResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.post(`auth/change-password`, data, environment.common_url);
  }

  public changeEmailConfirmation(
    data: ChangeEmailData
  ): Observable<ApiSuccessResponse<ChangePasswordResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.post(`auth/change-email-confirmation`, data, environment.common_url);
  }

  public changeEmail(
    data: ChangeEmailData
  ): Observable<ApiSuccessResponse<ChangePasswordResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.post(`auth/change-email`, data, environment.common_url);
  }

  public getNotifications(page: number, limit: number, isUnread: boolean): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page.toString(),
        limit: limit.toString(),
        hasRead: isUnread ? false : '',
        orderBy: 'createdAt:desc'
      }
    });
    return this.http.get(`announcements?${params.toString()}`, {}, environment.common_url);
  }

  public markAsRead(id: string): Observable<any> {
    return this.http.put(`announcements/${id}`, {}, environment.common_url);
  }

  public markAllAsRead(): Observable<any> {
    return this.http.put(`announcements`, {}, environment.common_url);
  }

  public getNotificationDetail(id: string): Observable<any> {
    return this.http.get(`announcements/${id}`, {}, environment.common_url);
  }
}
