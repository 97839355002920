import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postCode'
})
export class PostCodeMask implements PipeTransform {
  transform(number: number | string, length = 3, symbol = '-'): string {
    if (!number) return '';
    return `${number}`.substring(0, length) + symbol + `${number}`.substring(length);
  }
}
