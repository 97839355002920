import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable } from 'rxjs';

import { LoggedUserResponse, Role, RoleByName } from '../models/auth.model';
import { Tenant } from '../models/tenant.model';
import { HttpService } from './http.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class CheckPermissionService {
  private FIRST_LOGIN_STATUS = 2;
  public permissions = new BehaviorSubject<Array<string>>([]);

  constructor(
    private permissionsService: NgxPermissionsService,
    private tokenService: TokenService,
    private http: HttpService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.permissions.next([RoleByName.GUEST]);
  }

  public async initPermissions(tks: TokenService): Promise<Observable<Array<string>>> {
    // Remove all permissions
    this.permissionsService.flushPermissions();

    if (this.tokenService.hasLogged()) {
      await firstValueFrom(tks.setTenant());
      return await this.loadPermissions();
    }

    return this.permissions;
  }

  private async loadPermissions(): Promise<Observable<Array<string>>> {
    const resData: LoggedUserResponse = await this.getMe();

    if (resData.status == this.FIRST_LOGIN_STATUS) {
      this.router.navigate(['/auth/first-login']);
    }

    // Save logged user to localStorage
    this.tokenService.setUser(resData);

    //TODO: Can remove after long time system release
    this.tokenService.removeAllLocalStorageSetKeyByEmail();

    // Parse permissions
    const permissions = this.parsePermissions(resData);
    this.permissions.next(permissions);

    return this.permissions;
  }

  public async getMe(): Promise<LoggedUserResponse> {
    const domain = this.document.location.origin;
    const path = 'api/auth/me';
    const headers = {
      'x-client-domain': domain
    };
    const data = await lastValueFrom(this.http.getMe(domain, path, headers));

    return data.data as LoggedUserResponse;
  }

  private parsePermissions(data: LoggedUserResponse): Array<string> {
    if (data.isSystemAdmin) {
      return [RoleByName.SYSTEM_ADMIN];
    }

    const tenantId = this.tokenService.getTenant();
    if (tenantId) {
      const tenant: Tenant | undefined = data.tenants.find((x) => x.id == tenantId);

      const roleName: string | undefined = (Role[tenant?.role as number] as string) || undefined;

      return roleName ? [roleName] : [];
    }
    return [RoleByName.CONNECTED]; // TODO: check permisson buyer login

    // No permission granted
    return [];
  }
}
