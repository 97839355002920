<ul>
  <li
    *ngFor="let breadcrumb of breadcrumbs$ | async"
    class="font-normal text-base"
  >
    <a
      [routerLink]="[breadcrumb['url']]"
      [queryParams]="breadcrumb['queryParams']"
      routerLinkActive="router-link-active"
      (click)="onClickBreadcrumb(breadcrumb)"
      >{{ breadcrumb['label'] | translate }}</a
    >
  </li>
</ul>
