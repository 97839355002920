import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { FormBuilder, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { TranslateHelper } from 'src/app/core/helpers/translate.helper';

@Component({
  selector: 'input-text-comp',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true
    }
  ]
})
export class InputTextComponent implements OnInit {
  @Input() control: any = new FormControl();
  @Input() className: string | any = '';
  @Input() label: string | any = '';
  @Input() placeHolder: string | any = '';
  @Input() disabled: boolean = false;
  @Input() readAsLabel: boolean = false;
  @Input() isBorder: boolean = true;
  @Input() isEmail: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() maxLength: number = 1000;
  @Input() minLength: number = 0;
  @Output() handleErrorMessage: EventEmitter<any> = new EventEmitter();
  isDisabled: boolean = false;
  _control: any = new FormControl();

  value = '';

  constructor(private translateHelper: TranslateHelper) {}
  ngOnInit(): void {
    this._control = this.control;
    let listValidate = [Validators.minLength(this.minLength), Validators.maxLength(this.maxLength)];
    if (this.isRequired) {
      listValidate.push(Validators.required);
    }
    if (this.isEmail) {
      listValidate.push(Validators.email);
    }

    this._control.setValidators(Validators.compose(listValidate));
    this._control.valueChanges.subscribe((value: any) => {
      if (this._control.hasError('required')) {
        this.handleErrorMessage.emit(this.translateHelper.__t('common.required', { name: this.label }));
      } else if (this._control.hasError('minlength')) {
        this.handleErrorMessage.emit(
          this.translateHelper.__t('common.minLength', { maxLength: this.minLength, name: this.label })
        );
      } else if (this._control.hasError('maxlength')) {
        this.handleErrorMessage.emit(
          this.translateHelper.__t('common.maxLengthCommon', { maxLength: this.maxLength, name: this.label })
        );
      } else if (this._control.hasError('email')) {
        this.handleErrorMessage.emit(this.translateHelper.__t('common.email', { name: this.label }));
      }
    });
  }
}
