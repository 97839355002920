import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  titleDialog = '';
  contentDialog = '';
  confirmButtonText = '';
  cancelButtonText = '';
  loading: boolean;

  // *** PLEASE NOT BINDING WIDTH, HEIGHT ***

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      content: string;
      textConfirm: string;
      textCancel: string;
      handleConfirm: () => void;
      onCancel?: () => void;
      hasLoading?: boolean;
      okClass?: string;
      cancelClass?: string;
    },
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    this.titleDialog = this.data.title ? this.data.title : 'confirmDialog.title';
    this.contentDialog = this.data.content ? this.data.content : 'confirmDialog.content';
    this.confirmButtonText = this.data.textConfirm ? this.data.textConfirm : 'confirmDialog.textConfirm';
    this.cancelButtonText = this.data.textCancel ? this.data.textCancel : 'confirmDialog.textCancel';
  }

  onConfirm(): void {
    if (this.loading) return;

    if (this.data.hasLoading) {
      this.loading = true;
    }

    this.data.handleConfirm();

    if (!this.data.hasLoading) {
      this.dialogRef.close(true);
    }
  }

  onCancel(): void {
    if (this.data.onCancel) {
      this.data.onCancel();
    }

    this.dialogRef.close(false);
  }
}
