import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiSuccessResponse, ObjectWithUnknowProperty, ApiErrorResponse } from 'src/app/core/models/common.model';
import { RecordData } from 'src/app/core/models/master.model';
import { TenantResponse, TenantData, Currencies } from 'src/app/core/models/tenant.model';
import { HttpService } from 'src/app/core/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  constructor(private http: HttpService) {}

  public getBasicInformation(
    id: string
  ): Observable<ApiSuccessResponse<TenantResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`tenant/${id}`, {}, environment.common_url);
  }

  public update(
    id: string,
    data: TenantData
  ): Observable<ApiSuccessResponse<TenantResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.put(`tenant/${id}`, data, environment.common_url);
  }

  public getCurrency(
    tenant_id?: string
  ): Observable<ApiSuccessResponse<Currencies | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(
      `tenant-master/currencies`,
      {
        'x-tenant-id': tenant_id
      },
      environment.common_url
    );
  }

  public getTenantCustomer(
    params?: any
  ): Observable<ApiSuccessResponse<any | ObjectWithUnknowProperty> | ApiErrorResponse> {
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get(`tenant/showroom/has-customer?${httpParams.toString()}`, {});
  }

  public getMasterTenantShowroomCustomerItem(
    isValid = true
  ): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(
      `tenant-master/showroom-custom-item/showroom?isValid=${isValid ? '1' : '0'}`,
      {},
      environment.common_url
    );
  }
}
