/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-select-comp',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppSelectComponent),
      multi: true
    }
  ]
})
export class AppSelectComponent implements ControlValueAccessor {
  @Input() options: any[];
  @Input() valueKey = 'value';
  @Input() labelKey = 'label';
  @Input() className: string | any = '';
  @Input() selectClassName: string | any = '';
  @Input() placeHolder: string | any = '';
  @Input() readAsLabel: string | any = '';
  @Input() valueCenter = false;
  @Input() multiple = false;
  @Input() disabled = false;
  // @Input() disabled

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('matSelect') matSelect: any;

  onChange: any = () => {};
  onTouch: any = () => {};
  selected: any;

  optionsMap: Map<any, any> = new Map();
  isOpen = false;
  myClass = {};
  constructor() {}
  ngAfterViewInit() {
    // this.options
    for (const opts of this.options) {
      this.optionsMap.set(opts.id, opts);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(val: string) {
    this.selected = val;
  }

  onModelChange(e: string) {
    this.selected = e;
    this.onChange(e);
    this.change.emit({ value: e, option: this.optionsMap.get(e) || undefined });
  }

  onHandleKeyPress(event: KeyboardEvent) {
    const { key } = event;
    if (key === 'Enter') {
      event.preventDefault();
      this.onEnter.emit(this.selected);
    }
  }

  onHandleClear(event: Event) {
    this.onModelChange('');
    this.onClear.emit();
  }

  get getSelectOption() {
    const index = this.options.findIndex((item) => item.id === this.selected);
    if (index !== -1) return this.options?.[index]?.[this.labelKey] || '';
    return '';
  }

  flattenValue(option: any) {
    return option.toString();
  }
}
