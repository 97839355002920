<div
  *ngIf="isOpenSwitchTenant"
  @openCloseAnimation
  class="container-domain w-[335px] max-h-[250px] z-10 overflow-y-auto"
>
  <div
    class="domain flex flex-col gap-3 p-4 cursor-pointer"
    *ngFor="let domain of domains"
    (click)="switchDomain(domain)"
  >
    <div class="flex !text-[12px] items-center gap-[10px]">
      <div
        *ngIf="!getLogo(domain.logoUrl)"
        class="logo__block"
      >
        <img
          class="h-full w-auto"
          src="/assets/images/image-not-found.png"
        />
      </div>
      <div
        *ngIf="getLogo(domain.logoUrl)"
        class="logo__block"
      >
        <img
          class="h-full w-auto"
          [src]="getLogo(domain.logoUrl)"
        />
      </div>

      <span class="domain font-normal">{{ domain.name }}</span>
    </div>
  </div>
</div>
