<div class="flex items-center h-full">
  <div class="checkbox">
    <input
      type="checkbox"
      [ngModel]="checked"
      (ngModelChange)="onModelChange($event)"
      class="cursor-pointer"
    />
    <div
      class="line"
      [ngClass]="{
        active: checked
      }"
    ></div>
    <div
      class="icon"
      [ngClass]="{ right: checked }"
      #icon
    ></div>
  </div>
</div>
