import { Directive, ElementRef, Input } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[preventKeyCode]'
})
export class PreventKeyCodeDirective {
  @Input('preventKeyCode') keyCode: any;
  subscription = new Subscription();

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    const el = this.elRef.nativeElement;
    this.subscription = fromEvent(el.parentNode, 'keypress', { capture: true }).subscribe((e: any) => {
      if (this.keyCode && e.keyCode === Number(this.keyCode)) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
