import { A11yModule } from '@angular/cdk/a11y';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DialogModule } from '@angular/cdk/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkListboxModule } from '@angular/cdk/listbox';
import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IMaskModule } from 'angular-imask';

import { AdminGuard } from '../core/guards/admin.guard';
import { BuyerGuard } from '../core/guards/buyer.guard';
import { DeactivateGuard } from '../core/guards/deactive.guard';
import { SellerGuard } from '../core/guards/seller.guard';
import { TranslateHttpLoaderInterceptor } from '../core/interceptors/translate-http-loader.interceptor';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AppCheckBoxComponent } from './components/input/checkbox/checkbox.component';
import { AppInputComponent } from './components/input/input/input.component';
import { AutoCompleteComponent } from './components/input/autocomplete/autocomplete.component';
import { InputTextComponent } from './components/input/input-text/input-text.component';
import { InputNumberComponent } from './components/input/input-number/input-number.component';
import { AppRadioComponent } from './components/input/radio/radio.component';
import { AppSearchComponent } from './components/input/search/search.component';
import { AppSelectComponent } from './components/input/select/select.component';
import { NotifyComponent } from './components/notify/notify.component';
import { PaginatorCustomComponent } from './components/paginator-custom/paginator-custom.component';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { BannerDirective } from './directives/dialog-banner.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { PreventKeyCodeDirective } from './directives/prevent-key-code.directive';
import { TrimDirective } from './directives/trim.directive';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { PostCodeMask } from './pipes/postcode.pipe';
import { ElementTrackerDirective } from './directives/element-tracker.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

const MAT_MODULE = [
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule
];

const CDK_MODULE = [
  A11yModule,
  CdkAccordionModule,
  ClipboardModule,
  CdkListboxModule,
  CdkMenuModule,
  CdkStepperModule,
  CdkTableModule,
  CdkTreeModule,
  DragDropModule,
  OverlayModule,
  PortalModule,
  ScrollingModule,
  DialogModule,
  IMaskModule
];

const SHARED_COMPONENT = [
  AppSearchComponent,
  AppSelectComponent,
  AppInputComponent,
  AutoCompleteComponent,
  AppRadioComponent,
  UploadFileComponent,
  SlideToggleComponent,
  AppCheckBoxComponent,
  BreadcrumbComponent,
  ConfirmDialogComponent,
  NotifyComponent,
  PaginatorCustomComponent,
  InputTextComponent,
  InputNumberComponent
];

const SHARED_DIRECTIVE = [
  BannerDirective,
  OnlyNumberDirective,
  TrimDirective,
  PreventKeyCodeDirective,
  ElementTrackerDirective,
  SafeHtmlPipe
];

const PIPE = [PostCodeMask, LocalizedDatePipe];
@NgModule({
  imports: [
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    IMaskModule,
    ...MAT_MODULE,
    // ...PIPE,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderInterceptor,
        deps: [HttpClient]
      },
      extend: true
    })
  ],
  declarations: [...SHARED_COMPONENT, ...SHARED_DIRECTIVE, ...PIPE, PaginatorCustomComponent],
  providers: [
    AdminGuard,
    SellerGuard,
    BuyerGuard,
    DeactivateGuard,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' }
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true } }
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...CDK_MODULE,
    ...MAT_MODULE,
    ...SHARED_COMPONENT,
    ...SHARED_DIRECTIVE,
    ...PIPE
  ]
})
export class SharedModule {}
