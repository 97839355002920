/* eslint-disable @typescript-eslint/no-namespace */
import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { TokenService } from '../services/token.service';

@Injectable()
export class BuyerGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private tokenService: TokenService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.tokenService.hasLogged()) {
      return true;
    }

    this.router.navigate(['/auth/login'], {
      queryParams: {
        returnUrl: state.url
      }
    });
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.tokenService.hasLogged()) {
      return true;
    }
    this.router.navigate(['/auth/login'], {
      queryParams: {
        returnUrl: state.url
      }
    });
    return false;
  }
}

// @Injectable({
//   providedIn: 'root'
// })
// export class BuyerGuard {
//   constructor(private router: Router, private tokenService: TokenService) {}
//   canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     if (this.tokenService.hasLogged()) {
//       return true;
//     }

//     this.router.navigate(['/auth/login']);
//     return false;
//   }
// }

// @Injectable({
//   providedIn: 'root'
// })
// class PermissionsService {
//   constructor(private router: Router, private tokenService: TokenService) {}

//   canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
//     if (this.tokenService.hasLogged()) {
//       return true;
//     }

//     this.router.navigate(['/auth/login'], {
//       queryParams: {
//         returnUrl: state.url
//       }
//     });
//     return false;
//   }
// }

// export const BuyerGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
//   return inject(PermissionsService).canActivate(next, state);
// };

// export namespace BuyerGuard {
//   export const canActivate = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
//     const tokenService = inject(TokenService);
//     const router = inject(Router);

//     if (tokenService.hasLogged()) {
//       return true;
//     }

//     router.navigate(['/auth/login'], {
//       queryParams: {
//         returnUrl: state.url
//       }
//     });
//     return false;
//   };

//   export const canActivateChild = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
//     canActivate(route, state);
// }
