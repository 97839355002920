import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateHelper {
  constructor(private translate: TranslateService) {}

  __t(key: string, params?: Object): string {
    return this.translate.instant(key, params);
  }

  __m(code: string, params?: any): string {
    if (code?.includes('ERR') || code?.includes('INF') || code?.includes('COM') || code?.includes('MS')) {
      const key = `messages.${code}`;
      const text = this.__t(key, params);

      return text;
    }

    return this.__t(code, params);
  }

  __m2(code: string, params?: Object): string {
    if (code?.includes('ERR') || code?.includes('INF')) {
      const key = `messages.${code}`;
      const text = this.__t(key, params);

      return text;
    }

    return this.__t(code, params);
  }
}
