import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgxPermissionsService } from 'ngx-permissions';
import { TokenService } from 'src/app/core/services/token.service';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CheckPermissionService } from './core/services/check-permission.service';
import { getPaginatorIntl } from './core/services/pager.service';
import { SharedModule } from './shared/shared.module';

// Load permissions from BE
async function initializeApp(cps: CheckPermissionService, ns: NgxPermissionsService, tks: TokenService): Promise<void> {
  (await cps.initPermissions(tks)).subscribe({
    next: (permissions) => {
      ns.loadPermissions(permissions);
    }
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, SharedModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (cps: CheckPermissionService, ns: NgxPermissionsService, tks: TokenService) =>
        function () {
          return initializeApp(cps, ns, tks);
        },
      deps: [CheckPermissionService, NgxPermissionsService, TokenService],
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useValue: getPaginatorIntl()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
