import { Injectable, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HeadService {
  constructor(private title: Title, @Inject(DOCUMENT) private doc: any) {}
  setPageTitle(title: string) {
    this.title.setTitle(title);
  }
  getPageTitle() {
    return this.title.getTitle();
  }
  createLinkForCanonicalURL() {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);
  }
  createLinkHead(href: string, rel: string) {
    const link: HTMLLinkElement = this.doc.createElement('link');
    const favicon: HTMLLinkElement = this.doc.querySelector('link[rel="icon"]');
    link.setAttribute('rel', rel);
    this.doc.head.insertBefore(link, favicon);
    link.setAttribute('href', href);
  }
  createMetaHead(name: string, content: string) {
    const meta: HTMLMetaElement = this.doc.createElement('meta');
    meta.setAttribute('name', name);
    this.doc.head.appendChild(meta);
    meta.setAttribute('content', content);
  }
}
