import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreloadAllModules } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { LoggedDashboardComponent } from './shared/components/logged-dashboard/logged-dashboard.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { NotPermittedComponent } from './shared/components/not-permitted/not-permitted.component';
import { SellerGuard } from './core/guards/seller.guard';
import { BuyerGuard } from './core/guards/buyer.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { DeactivateGuard } from './core/guards/deactive.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: '',
    component: LoggedDashboardComponent,
    children: [
      {
        path: '',
        canActivateChild: [BuyerGuard],
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'tenant',
        canActivateChild: [AdminGuard],
        loadChildren: () => import('./modules/tenant/tenant.module').then((m) => m.TenantModule),
        data: {
          breadcrumb: '設定',
          modifyPath: '/tenant/basic-setting'
        }
      },
      {
        path: 'profile',
        canActivateChild: [BuyerGuard],
        loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
        data: {
          breadcrumb: '設定',
          modifyPath: '/profile/setting'
        },
        canDeactivate: [DeactivateGuard]
      },
      {
        path: 'db-material',
        canActivateChild: [SellerGuard],
        loadChildren: () => import('./modules/db-material/db-material.module').then((m) => m.DbMaterialModule),
        data: {
          breadcrumb: '商品データベース'
        }
      },
      {
        path: 'order',
        canActivateChild: [BuyerGuard],
        loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule)
      },
      {
        path: 'showroom',
        canActivateChild: [BuyerGuard],
        loadChildren: () => import('./modules/showroom/showroom.module').then((m) => m.ShowroomModule),
        data: {
          breadcrumb: 'Showroom管理'
        }
      }
    ]
  },
  {
    path: '403',
    component: NotPermittedComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  providers: [],
  exports: [RouterModule]
})
export class AppRoutingModule {}
