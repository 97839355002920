import { Component, EventEmitter, Input, OnChanges, OnInit, Output, forwardRef } from '@angular/core';
import { FormBuilder, FormControl, NG_VALUE_ACCESSOR, Validators, FormGroup } from '@angular/forms';
import { TranslateHelper } from 'src/app/core/helpers/translate.helper';
import { UtilsHelper } from 'src/app/core/helpers/utils.helper';
import { FormAbstractComponent } from 'src/app/shared/abstracts/form.abstract';

@Component({
  selector: 'input-number-comp',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true
    }
  ]
})
export class InputNumberComponent extends FormAbstractComponent {
  @Input() control: any = new FormControl();
  @Input() className: string | any = '';
  @Input() label: string | any = '';
  @Input() placeHolder: string | any = '';
  @Input() disabled: boolean = false;
  @Input() readAsLabel: boolean = false;
  @Input() isBorder: boolean = true;
  @Input() isRequired: boolean = false;
  @Output() handleErrorMessage: EventEmitter<any> = new EventEmitter();
  isDisabled: boolean = false;
  _control: any = new FormControl();

  value = '';
  inputMask: any;
  override _form: FormGroup<{}>;

  constructor(private translateHelper: TranslateHelper, private utils: UtilsHelper) {
    super();

    this.inputMask = this.utils.inputMask();
  }
  ngOnInit(): void {
    this._control = this.control;
    let listValidate = [];
    if (this.isRequired) {
      listValidate.push(Validators.required);
    }

    this._control.setValidators(Validators.compose(listValidate));
    this._control.valueChanges.subscribe((value: any) => {
      if (this._control.hasError('required')) {
        this.handleErrorMessage.emit(this.translateHelper.__t('common.required', { name: this.label }));
      }
    });
  }
}
