<div class="dialog-confirm p-10 text-center flex flex-col gap-8 !w-[370px] !h-auto">
  <div class="text-[14px] font-bold">
    {{ titleDialog | translate }}
  </div>
  <div>
    {{ contentDialog | translate }}
  </div>

  <div class="flex flex-col items-center gap-4">
    <button
      color="primary"
      mat-raised-button
      class="w-40 !bg-[#515151] !shadow-none"
      [ngClass]="data.okClass"
      (click)="onConfirm()"
    >
      <mat-icon
        class="!w-6 !h-6"
        *ngIf="loading"
      >
        <mat-progress-spinner
          color="accent"
          diameter="18"
          mode="indeterminate"
        ></mat-progress-spinner>
      </mat-icon>
      <span class="text-white py-2 font-bold text-[14px]">
        {{ confirmButtonText | translate }}
      </span>
    </button>
    <button
      mat-raised-button
      mat-stroked-button
      (click)="onCancel()"
      class="w-40 !border-[#515151] !shadow-none"
      [ngClass]="data.cancelClass"
    >
      <span class="py-2 !text-[#515151] font-bold text-[14px]">
        {{ cancelButtonText | translate }}
      </span>
    </button>
  </div>
</div>

<button
  mat-icon-button
  aria-label="close dialog"
  (click)="onCancel()"
  class="el-close-dialog"
>
  <mat-icon>close</mat-icon>
</button>
