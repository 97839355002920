import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditStatusService {
  private isEditing = false;
  private sortting = false;

  getEditingStatus(): boolean {
    return this.isEditing;
  }

  setEditingStatus(status: boolean): void {
    this.isEditing = status;
  }

  getSortStatus(): boolean {
    return this.sortting;
  }

  setSortStatus(status: boolean): void {
    this.sortting = status;
  }
}
