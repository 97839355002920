<div class="p-10 flex flex-col gap-4 h-full">
  <div class="mr-3 text-2xl font-semibold mb-4">
    {{ 'topbar.profile.detailNotifyTitle' | translate }}
  </div>
  <div class="text-[14px] font-bold">
    {{ titleDialog | translate }}
  </div>
  <div class="flex-1 overflow-hidden">
    <div class="scrollCustom h-full overflow-y-auto">
      <div [innerHTML]="contentDialog"></div>
    </div>
  </div>

  <div class="flex flex-col items-center gap-4">
    <button
      mat-raised-button
      mat-stroked-button
      mat-dialog-close
      class="w-40 !border-[#515151] !shadow-none"
    >
      <span class="py-2 !text-[#515151] font-bold text-[14px]">
        {{ cancelButtonText | translate }}
      </span>
    </button>
  </div>
</div>

<button
  mat-icon-button
  aria-label="close dialog"
  mat-dialog-close
  class="el-close-dialog"
>
  <mat-icon>close</mat-icon>
</button>
