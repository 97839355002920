<div
  class="input"
  [ngClass]="className"
>
  <input
    matInput
    class="form-input outline-none w-full form-input-border"
    [ngClass]="{
      'form-input-label': readAsLabel,
      'form-input-border': isBorder,
    }"
    [placeholder]="placeHolder"
    [disabled]="isDisabled"
    [imask]="inputMask"
    [unmask]="true"
    [ngModel]="value"
  />
</div>
