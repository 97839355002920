import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

enum BannerType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info'
}

@Directive({
  selector: '[appBanner]'
})
export class BannerDirective {
  @Input() timeout = 3000;
  timeoutCallback?: any;
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.handleStyleElmt();
  }

  ngOnDestroy() {
    if (this.timeoutCallback) {
      clearTimeout(this.timeoutCallback);
    }
  }

  handleStyleElmt() {
    this.el.nativeElement.style.minHeight = '32px';
    this.el.nativeElement.style.backgroundColor = 'transparent';
    this.el.nativeElement.classList.add(
      'flex',
      'items-center',
      'gap-3',
      'px-8',
      'px-5',
      'my-2',
      'text-[14px]',
      '-z-[1]',
      'hidden'
    );
  }

  error(text: string) {
    this.handleClearElmt();
    this.el.nativeElement.style.backgroundColor = '#E2E2E2';
    this.el.nativeElement.classList.add('!z-[100]');
    this.handleCreateElmt(BannerType.ERROR, text, 'text-[#C00000]');
    this.callback();
  }

  success(text: string) {
    this.handleClearElmt();
    this.el.nativeElement.style.backgroundColor = '#E2E2E2';
    this.el.nativeElement.classList.add('!z-[100]');
    this.handleCreateElmt(BannerType.SUCCESS, text, 'text-[#343dd6]');
    this.callback();
  }

  info(text: string) {
    this.handleClearElmt();
    this.el.nativeElement.style.backgroundColor = '#E2E2E2';
    this.el.nativeElement.classList.add('!z-[100]');
    this.handleCreateElmt(BannerType.INFO, text, 'text-[#343dd6]');
    this.callback();
  }

  handleCreateElmt(type: BannerType, text: string, className?: string) {
    this.el.nativeElement.classList.remove('hidden');
    const div = document.createElement('span');
    div.className = `${className}`;
    div.innerHTML = text;

    const svg = document.createElement('div');
    if (type === BannerType.ERROR) {
      svg.innerHTML = `<svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99964 12.548C9.14842 12.548 9.27076 12.5002 9.36668 12.4045C9.46259 12.3088 9.51054 12.1866 9.51054 12.0378C9.51054 11.889 9.4627 11.7667 9.36701 11.6708C9.27133 11.5749 9.14909 11.5269 9.00032 11.5269C8.85154 11.5269 8.7292 11.5748 8.63329 11.6705C8.53737 11.7661 8.48942 11.8884 8.48942 12.0371C8.48942 12.1859 8.53726 12.3083 8.63295 12.4042C8.72864 12.5001 8.85087 12.548 8.99964 12.548ZM8.61634 9.90287H9.46727V5.22403H8.61634V9.90287ZM9.00623 16.125C8.01929 16.125 7.09493 15.938 6.23316 15.564C5.37138 15.19 4.61666 14.6793 3.969 14.0319C3.32133 13.3845 2.81041 12.6297 2.43624 11.7673C2.06208 10.905 1.875 9.97985 1.875 8.99186C1.875 8.00868 2.062 7.08453 2.436 6.21941C2.81 5.3543 3.32069 4.60104 3.96806 3.95963C4.61545 3.3182 5.37031 2.81041 6.23263 2.43624C7.09496 2.06208 8.02011 1.875 9.0081 1.875C9.99129 1.875 10.9154 2.062 11.7806 2.436C12.6457 2.81 13.3989 3.31756 14.0403 3.95869C14.6818 4.59983 15.1896 5.35376 15.5637 6.22048C15.9379 7.08719 16.125 8.01161 16.125 8.99374C16.125 9.98068 15.938 10.905 15.564 11.7668C15.19 12.6286 14.6824 13.3822 14.0413 14.0275C13.4001 14.6729 12.6462 15.1838 11.7795 15.5603C10.9128 15.9367 9.98835 16.125 9.00623 16.125ZM9.00936 15.274C10.7493 15.274 12.2283 14.663 13.4466 13.4408C14.6649 12.2187 15.274 10.7353 15.274 8.99061C15.274 7.25071 14.6661 5.77162 13.4501 4.55334C12.2342 3.33508 10.7508 2.72595 8.99998 2.72595C7.26152 2.72595 5.78123 3.33391 4.55912 4.54984C3.33701 5.76576 2.72595 7.24914 2.72595 8.99998C2.72595 10.7384 3.33701 12.2187 4.55912 13.4408C5.78123 14.663 7.26464 15.274 9.00936 15.274Z"
        fill="#C00000"
      />
    </svg>`;
    } else if (type === BannerType.INFO || type === BannerType.SUCCESS) {
      svg.innerHTML = `<svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.89373 12.1601L12.9476 7.10623L12.2942 6.46442L7.89373 10.8721L5.67259 8.65095L5.03797 9.29276L7.89373 12.1601ZM9.00124 16.125C8.02347 16.125 7.10224 15.938 6.23754 15.564C5.37284 15.19 4.61666 14.6793 3.969 14.0319C3.32133 13.3845 2.81041 12.6287 2.43624 11.7644C2.06208 10.9 1.875 9.97899 1.875 9.00124C1.875 8.01579 2.062 7.08951 2.436 6.22241C2.81 5.3553 3.32069 4.60104 3.96806 3.95963C4.61545 3.3182 5.3713 2.81041 6.23561 2.43624C7.09994 2.06208 8.02098 1.875 8.99873 1.875C9.98418 1.875 10.9105 2.062 11.7776 2.436C12.6447 2.81 13.3989 3.31756 14.0403 3.95869C14.6818 4.59983 15.1896 5.35376 15.5637 6.22048C15.9379 7.08719 16.125 8.01328 16.125 8.99873C16.125 9.97649 15.938 10.8977 15.564 11.7624C15.19 12.6271 14.6824 13.3833 14.0413 14.031C13.4001 14.6786 12.6462 15.1896 11.7795 15.5637C10.9128 15.9379 9.98669 16.125 9.00124 16.125ZM8.99998 15.274C10.7461 15.274 12.2283 14.663 13.4466 13.4408C14.6649 12.2187 15.274 10.7384 15.274 8.99998C15.274 7.25383 14.6649 5.77162 13.4466 4.55334C12.2283 3.33508 10.7461 2.72595 8.99998 2.72595C7.26152 2.72595 5.78123 3.33508 4.55912 4.55334C3.33701 5.77162 2.72595 7.25383 2.72595 8.99998C2.72595 10.7384 3.33701 12.2187 4.55912 13.4408C5.78123 14.663 7.26152 15.274 8.99998 15.274Z"
        fill="#343DD6"
      />
    </svg>`;
    }

    this.renderer.appendChild(this.el.nativeElement, svg);
    this.renderer.appendChild(this.el.nativeElement, div);
  }

  callback() {
    if (this.timeoutCallback) {
      clearTimeout(this.timeoutCallback);
    }
    this.timeoutCallback = setTimeout(() => {
      this.handleClearElmt();
      this.handleStyleElmt();
    }, this.timeout);
  }

  handleClearElmt() {
    this.el.nativeElement.innerHTML = '';
    this.el.nativeElement.classList.remove('!z-[100]');
  }
}
